import { setQuestionnairesAction } from '@actions/questions.actions';
import {
  clearSessionsList,
  pushToSessionListAction,
  setPostSessionErrorAction,
} from '@actions/sessions.actions';
import { inject, Injectable } from '@angular/core';
import { SessionsApiService } from '@api/sessions-api.service';
import { IQuestionnaireUI } from '@models/question.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  from,
  map,
  merge,
  mergeMap,
  of,
  toArray,
  withLatestFrom,
} from 'rxjs';
import { NotificationsService } from 'src/app/services/notifications.service';
import { sessionListSelector$ } from 'src/app/store/selectors/session.selectors';
import { userNameSelector } from 'src/app/store/selectors/user.selectors';

@Injectable()
export class SessionsEffects {
  private actions$ = inject(Actions);
  private sessionsApi = inject(SessionsApiService);
  private _store = inject(Store);
  private _nots = inject(NotificationsService);

  loadQuestionsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pushToSessionListAction),
      withLatestFrom(
        this._store.select(sessionListSelector$),
        this._store.select(userNameSelector)
      ),
      mergeMap(([, sessions, username]) =>
        from(sessions).pipe(
          mergeMap((session) =>
            this.sessionsApi.SaveSession(username, session)
          ),
          toArray()
        )
      ),
      map(() => {
        console.log('sessions saved');
        return clearSessionsList();
      }),
      catchError((error) => {
        this._nots.showToast('Error saving game session', 'toast-alert', 2000);
        return of(setPostSessionErrorAction({ error }));
      })
    )
  );
}

//   this.sessionsApi.SaveSession({}).pipe(
//     map((d) => {
//       return clearSessionsList();
//     }),
//     catchError((error) => {
//       return of(setPostSessionErrorAction({ error }));
//     })
//   )\
