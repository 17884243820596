import { UserEffects } from '@effects/user.effects';
import { ContinentEffects } from './continent.effects';
import { CountryEffects } from './country.effects';
import { ProgressEffects } from './progress.effects';
import { QuestEffects } from './quest.effects';
import { RouterEffects } from './router.effects';
import { SettingsEffects } from './settings.effects';
import { TimerEffects } from './timer.effects';
import { ResponsesEffects } from './responses.effects';
import { ToolbarEffects } from './toolbar.effects';
import { QuestionsEffects } from './questions.effects';
import { SoundEffects } from './sounds.effects';
import { AuthEffects } from './auth.effects';
import { RegistrationEffects } from './registration.effects';
import { ParamsEffects } from './params.effects';
import { SessionsEffects } from '@effects/sessions.effects';

export const AppEffects = [
  UserEffects,
  QuestEffects,
  RouterEffects,
  SettingsEffects,
  TimerEffects,
  ContinentEffects,
  CountryEffects,
  ProgressEffects,
  ResponsesEffects,
  ToolbarEffects,
  QuestionsEffects,
  RegistrationEffects,
  SoundEffects,
  AuthEffects,
  ParamsEffects,
  SessionsEffects,
];
