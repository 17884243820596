import {
  continentActionTypes,
  setContinentsAction,
} from '@actions/continent.actions';
import {
  confirmUnconfirmedResponsesAction,
  recallUnconfirmedResponsesAction,
  responsesActionTypes,
  storeUnsentResponsesAction,
} from '@actions/responses.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { ResponsesApiService } from '@api/responses-api.service';
import { LocalNotificationsService } from '../../services/helpers/local-notifications.service';
import { timeBetweenQuestionnairesSelector$ } from '../selectors/params.selector';

@Injectable()
export class ResponsesEffects {
  // sendUnsentResponsesEffect

  // Triggered from the sendUnsentResponsesAction in the timer scheduler.
  // This effect makes a post request to the BackEnd.
  // If the post is unsuccessful the recallUnconfirmedResponsesAction is triggered
  // If the post is successful the confirmUnconfirmedResponsesAction is triggered

  //TRIGGERS the storeUnsentResponsesAction //  the confirmUnconfirmedResponsesAction // the recallUnconfirmedResponsesAction

  sendUnsentResponsesEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(responsesActionTypes.SEND_UNSENT_RESPONSES),
        withLatestFrom(this.store.select(timeBetweenQuestionnairesSelector$)),
        map(([{ responses }, qTimers]) => {
          const outcome: string = '';
          this.responsesApiService.postResponses(responses);
          this.store.dispatch(storeUnsentResponsesAction());

          // questionaire now should be availble in the next x hours
          this._localNots.SendQuestionnairIsReadyNotification(qTimers.hours);
        })
      ),
    { dispatch: false }
  );

  // sendUnconfirmedResponsesEffect
  // Triggered from the  sendUnconfirmedResponsesAction in the appComponent onInit.
  // This effect makes a post request to the BackEnd.
  // If the post is unsuccessful the recallUnconfirmedResponsesAction is triggered
  // If the post is successful the confirmUnconfirmedResponsesAction is triggered

  //TRIGGERS  the confirmUnconfirmedResponsesAction // the recallUnconfirmedResponsesAction
  sendUnconfirmedResponsesEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(responsesActionTypes.SEND_UNCONFIRMED_RESPONSES),
        map(({ responses }) => {
          const outcome: string = '';

          this.store.dispatch(storeUnsentResponsesAction());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private responsesApiService: ResponsesApiService,
    private _localNots: LocalNotificationsService
  ) {}
}
