import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Action, ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { appReducers } from '@reducers/app.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppEffects } from '@effects/index';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AppState } from '@state/app.state';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TokenInterceptorService } from './@core/interceptors/token.interceptors';
import { GlobalHttpErrorHandler } from './@core/interceptors/global-http-error.interceptor';
import { MobileAvailableScreenComponent } from './components/mobile-available-screen/mobile-available-screen.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { GlobalSystemErrorHandler } from './@core/interceptors/global-system-error.interceptor';
import { AuthActionsTypes } from '@actions/auth.actions';
import { ComponentsModule } from './components/components.module';

import * as Sentry from '@sentry/angular-ivy';
import { LoadingService } from './services/loading.service';

export function logoutMetaReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return (state: any, action: Action) => {
    if (action.type === AuthActionsTypes.LOGOUT_USER) {
      // If the Logout action is dispatched, return the initial state
      return reducer(undefined, action);
    }

    // Otherwise, return the next state
    return reducer(state, action);
  };
}
export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { quest: ['activeQuest'] },
      'settings',
      'quests',
      'user',
      'timer',
      'country',
      'toolbar',
      'progress',
      'responses',
      'sounds',
      'games',
      'registration',
      'sessions',
    ],
    rehydrate: true,
  })(reducer);
}
const metaReducers: Array<MetaReducer<AppState, any>> = [
  localStorageSyncReducer,
  logoutMetaReducer,
];

const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter: tokenGetter,
    // whitelistedDomains: yourWhitelistedDomains,
  },
};
export function tokenGetter() {
  return localStorage.getItem('token');
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [AppComponent, MobileAvailableScreenComponent],
  imports: [
    FormsModule,
    ComponentsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    JwtModule.forRoot(JWT_Module_Options),
    OAuthModule.forRoot(),
    StoreModule.forRoot(appReducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: true, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),

    EffectsModule.forRoot([...AppEffects]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    LoadingService,
    ScreenOrientation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpErrorHandler,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
