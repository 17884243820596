import { IGlobalParams } from '@models/params.model';
import { createAction, props } from '@ngrx/store';

export type TSession = {
  gameId?: number;
  end?: Date;
  score?: number;
  start?: Date;
};

export enum sessionActionTypes {
  START_SESSION = '[SESSION] START',
  SET_ACTIVE_SESSION = '[SESSION] SET ACTIVE SESSION',
  SET_SESSION_LIST = '[SESSION] SET SESSION LIST',
  UPDATE_ACTIVE_SESSION = '[SESSION] UPDATE ACTIVE SESSION',
  PUSH_TO_SESSION_LIST = '[SESSION] PUSH TO SESSION LIST',
  CLEAR_SESSIONS = '[SESSION] CLEAR_SESSIONS',
  SESSIONS_POST_SUCCESS = '[SESSION] SESSIONS_POST_SUCCESS',
  SESSIONS_POST_ERROR = '[SESSION] SESSIONS_POST_ERROR',
}

export const setActiveSessionAction = createAction(
  sessionActionTypes.SET_ACTIVE_SESSION,
  props<{ params: any }>()
);
export const startSessionAction = createAction(
  sessionActionTypes.START_SESSION
);
export const setSessionListAction = createAction(
  sessionActionTypes.SET_SESSION_LIST
);
export const updateActiveSession = createAction(
  sessionActionTypes.UPDATE_ACTIVE_SESSION,
  props<TSession>()
);
export const pushToSessionListAction = createAction(
  sessionActionTypes.PUSH_TO_SESSION_LIST
);
export const clearSessionsList = createAction(
  sessionActionTypes.CLEAR_SESSIONS
);

export const setPostSessionSuccessAction = createAction(
  sessionActionTypes.SESSIONS_POST_SUCCESS,
  props<{ message: string }>()
);
export const setPostSessionErrorAction = createAction(
  sessionActionTypes.SESSIONS_POST_ERROR,
  props<{ error: string }>()
);
