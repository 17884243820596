import { settingsActionTypes } from '@actions/settings.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SettingsEffects {
  setActiveLanguageEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(settingsActionTypes.SET_ACTIVE_LANGUAGE),
        tap(({ langCode }) => {
          this.translate.use(langCode);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private translate: TranslateService) {}
}
