import { setActiveMiniGame } from '@actions/games.actions';
import {
  clearSessionsList,
  pushToSessionListAction,
  setActiveSessionAction,
  startSessionAction,
  updateActiveSession,
} from '@actions/sessions.actions';
import { createReducer, on } from '@ngrx/store';
import { IGamesState } from '@state/games.state';
import { ISessionState } from '@state/session.state';

export const sessionInitialState: ISessionState = {
  activeSession: undefined,
  sessionList: [],
};

export const sessionReducer = createReducer(
  sessionInitialState,
  on(setActiveSessionAction, (state, { params }) => {
    if (state.activeSession) {
      return {
        ...state, // Ensures the rest of the state is copied over
        sessionList: [
          ...state.sessionList,
          { ...state.activeSession, end: params.start },
        ], // Creates a new array with the activeSession appended
        activeSession: { ...params, score: 0 }, // Sets activeSession to null
      };
    } else {
      return {
        ...state,
        activeSession: { ...params, score: 0 },
      };
    }
  }),
  on(updateActiveSession, (state, { score, gameId, end }) => {
    return {
      ...state,
      activeSession: {
        ...state.activeSession,
        score: score ? score : state.activeSession?.score,
        gameId: gameId ? gameId : state.activeSession?.gameId,
        end,
      },
    };
  }),
  on(pushToSessionListAction, (state) => {
    console.log('session state pushing', state);
    if (state.activeSession) {
      return {
        sessionList: [...state.sessionList, state.activeSession], // Creates a new array with the activeSession appended
        activeSession: undefined, // Sets activeSession to null
      };
    } else {
      return {
        ...state,
      };
    }
  }),
  on(clearSessionsList, (state) => {
    return {
      ...state,
      sessionList: [],
    };
  })
);
