import { Injectable } from '@angular/core';
import {
  LocalNotifications,
  LocalNotificationSchema,
} from '@capacitor/local-notifications';
import { AppPermissionsService } from './permissions.service';
import { ITimeDuration } from '../../models/timer.model';
import { Store } from '@ngrx/store';
import { openQuestionnaireModal } from '../../store/actions/questions.actions';
import { PlatformService } from '../platform.service';

const NotificationTypes = {
  new_questions_ready: 'new_questions_ready',
};
@Injectable({
  providedIn: 'root',
})
export class LocalNotificationsService {
  constructor(
    private _permissions: AppPermissionsService,
    private store: Store,
    private _plt: PlatformService
  ) {}

  public async CheckNotificationPermissions() {
    try {
      const isWeb = this._plt.IsBrowser;
      if (isWeb) return;
      const { display } = await LocalNotifications.requestPermissions();
      if (display === 'denied') {
        this._permissions.PropmptOpenSettings();
      } else {
        this.setListeners();
      }
    } catch (error) {
      throw error;
    }
  }

  public setListeners() {
    LocalNotifications.removeAllListeners();
    const k = LocalNotifications.addListener(
      'localNotificationActionPerformed',
      (notif) => {
        console.log('notif 2', notif);
        if (
          notif.notification.actionTypeId ===
          NotificationTypes.new_questions_ready
        ) {
          this.store.dispatch(openQuestionnaireModal());
        }
      }
    );
  }

  private generateRepeatedDates(everyHours: number) {
    const dates: Date[] = [];
    const now = new Date();
    const totalHours = 14 * 24; // Total hours in two weeks

    for (let i = 0; i <= totalHours; i += everyHours) {
      const date = new Date(now.getTime() + i * 60 * 60 * 1000); // Add hours in milliseconds
      dates.push(date);
    }

    return dates;
  }
  private getNotificationData(title: string, body: string, at: Date) {
    return {
      actionTypeId: NotificationTypes.new_questions_ready,
      title,
      body,
      id: this.getRandomArbitrary(0, 999999),
      schedule: {
        at,
      },
      smallIcon: 'res://ic_stat_notify.png',
    } as LocalNotificationSchema;
  }
  ScheduleLocalNotifications(title: string, body: string, dates: Date[]) {
    return LocalNotifications.schedule({
      notifications: dates.map((date) =>
        this.getNotificationData(title, body, date)
      ),
    });
  }
  addHoursToCurrentDate(hours: number, minutes: number, seconds: number) {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + hours);
    currentDate.setMinutes(currentDate.getMinutes() + minutes);
    currentDate.setSeconds(currentDate.getSeconds() + seconds);
    return currentDate;
  }

  public async SendQuestionnairIsReadyNotification(hours: number) {
    await this.RemovePendingNotifications();
    console.log('-------SENDING SCHEDULED QUESTIONS ---------');

    const dates = this.generateRepeatedDates(hours);
    console.log('GENERATED DATES:', dates);

    const notification = this.ScheduleLocalNotifications(
      'Save The World',
      "Today's questionnaire is ready",
      dates
    );

    notification.then((res) => {
      console.log('LOCAL NOTIFICATION: ', res);
    });

    console.log('-------FINISH SCHEDULED QUESTIONS ---------');
  }

  private RemovePendingNotifications() {
    console.log('-------Removing scheduled notifications ---------');
    return new Promise(async (resolve, reject) => {
      try {
        const { notifications } = await LocalNotifications.getPending();
        if (notifications && notifications.length > 0) {
          LocalNotifications.cancel({ notifications });
        }

        console.log('-------Scheduled notifications REmoved ---------');
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async SendTestNotification(
    seconds: number,
    title = 'Save The World',
    desc = "Today's questionnaire is ready"
  ) {
    const perm = await LocalNotifications.requestPermissions();
    const featdate = this.addHoursToCurrentDate(0, 0, seconds);
    this.ScheduleLocalNotifications(title, desc, [featdate]);
  }

  public pendingNots() {
    LocalNotifications.getPending().then((res) => {
      console.log('PENDING NOTIFICATIONS: ', res);
    });
  }
  private getRandomArbitrary(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
