import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { App, AppPlugin, URLOpenListenerEvent } from '@capacitor/app';
import { Store } from '@ngrx/store';
import { PluginListenerHandle } from '@capacitor/core';
import { PlatformService } from '../platform.service';
import { environment } from '../../../environments/environment';
import {
  loadGarminCodeAction,
  setGarminCode,
} from '../../store/actions/user.actions';

@Injectable({
  providedIn: 'root',
})
export class GarminIntegrationService {
  private GARMIN_PARAMS = {
    id: 'garmin_id',
    permissions: 'has_permissions',
  };
  private app!: Promise<PluginListenerHandle>;
  constructor(
    private zone: NgZone,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private plfSrv: PlatformService
  ) { }

  public get GarminRedirectUri() {
    const redParam = this.GarminRedirectParams();
    return `${environment.GARMIN_URL}?redirect_uri=${redParam}`;
  }

  private GarminRedirectParams(): string | undefined {
    const isAndroid = this.plfSrv.IsNativeAndroid;
    const isIos = this.plfSrv.IsNativeIOS;
    const isWeb = this.plfSrv.IsBrowser;

    if (isAndroid) return environment.GARMIN_REDIRECTS.android;
    if (isIos) return environment.GARMIN_REDIRECTS.ios;
    if (isWeb) return environment.GARMIN_REDIRECTS.web_url;
    return;
  }

  public CheckGarminWebRedirect() {
    this.urlHasGarminParams(this.activatedRoute.snapshot.queryParams);
  }
  private urlHasGarminParams(queryParams: { [key: string]: any }) {
    const garminCode = queryParams[this.GARMIN_PARAMS.id];
    const hasPermissionToGetData =
      (queryParams[this.GARMIN_PARAMS.permissions] as string)?.toLowerCase() ===
      'true';
    console.log('hasPermissionToGetData', hasPermissionToGetData);

    console.log(garminCode, 'garminCode');

    if (!hasPermissionToGetData) return;
    if (garminCode) {
      this.store.dispatch(setGarminCode({ garminCode }));
    } else {
      this.store.dispatch(loadGarminCodeAction());
    }
  }

  public CheckGarminCBFromNativeRedirects() {
    this.app = App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      let url = new URL(event.url);

      this.zone.run(() => {
        // Building a query param object for Angular Router
        const queryParams: Params = {};
        for (const [key, value] of url.searchParams.entries()) {
          queryParams[key] = value;
        }
        const garminCode = queryParams[this.GARMIN_PARAMS.id];
        const hasPermissionToGetData =
          (
            queryParams[this.GARMIN_PARAMS.permissions] as string
          )?.toLowerCase() === 'true';
        // Add query params to current route
        if (!hasPermissionToGetData) return;

        console.log(
          'in CheckGarminCBFromNativeRedirects',
          queryParams['garmin_id']
        );
        if (queryParams && garminCode) {
          this.store.dispatch(
            setGarminCode({ garminCode: queryParams['garmin_id'] })
          );

          // this.router.navigate([routePaths.auth.register.step_2]);
        } else {
          this.store.dispatch(loadGarminCodeAction());
        }
      });
    });
  }
}
