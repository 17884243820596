import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Observable, of, tap, throwError } from 'rxjs';
import { TSession } from '@actions/sessions.actions';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class SessionsApiService {
  private API_URL = environment.API_URL;

  private url = `${this.API_URL}/game-session`;
  constructor(private http: HttpClient) {}

  SaveSession(username: string, session: TSession) {
    const start = moment(session.start).format('YYYY-MM-DD HH:mm:ss');
    const end = moment(session.end).format('YYYY-MM-DD HH:mm:ss');
    return this.http.post(`${this.url}`, {
      ...session,
      game_name: `${session.gameId}`,
      start,
      end,
      username,
    }) as Observable<any>;
  }
}
