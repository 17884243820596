import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IGamesState } from '@state/games.state';
import { ISessionState } from '@state/session.state';

export const selectSessionState =
  createFeatureSelector<ISessionState>('sessions');

export const activeSessionSelector$ = createSelector(
  selectSessionState,
  (sessionState) => {
    return sessionState.activeSession;
  }
);

export const sessionListSelector$ = createSelector(
  selectSessionState,
  (sessionState) => {
    return sessionState.sessionList;
  }
);
